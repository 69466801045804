<template>
	<div
		class="position-fixed-full radius-20 z-index-layer"
	>
		<div class="bg-layer"></div>
		<div
			class="position-relative viewer pa-20 flex-column justify-center full-width full-height"
		>
			<div>
				<slot name="body"></slot>
			</div>
		</div>

	</div>
</template>

<script>
export default {
	name: 'PopupLayer'
}
</script>

<style>

.z-index-layer { z-index:  999}
.position-fixed-full { position: fixed; left: 0; top: 0; width: 100%; height: 100%;}
.bg-layer { position: absolute; left: 0; top: 0; width: 100%; height: 100%; background-color: black; opacity: 0.3}
.position-relative { position: relative }
</style>