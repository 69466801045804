<template>
	<div class="ptb-10 bg-white justify-space-between">

		<div
			v-show="option.is_cnt"
			class="ml-10 flex-column justify-center"
		>
			<span >총 <span class="font-weight-bold color-red size-em-12 ">{{ option.tCnt | makeComma }}</span> 건</span>
		</div>

		<div>
			<template
				v-if="option.agency"
			>
				<template
					v-for="(agency_type, index) in codes.upAgencyList"
				>
					<select
						v-if="agency_type.use && agency_type.view.indexOf(user ? user.roleId : '') > -1"
						v-model="agency[agency_type.key]"
						:key="'agency_type_' + index"
						class="input-box-inline ml-10"
						@change="getAgencyList($event, agency_type.key)"
						:ref="agency_type.key"
					>
						<option :value="''">{{ agency_type.name }}</option>
						<template
							v-for="(agency, sub_index) in agency_list[agency_type.key]"
						>
						<option
							v-if="!user[agency_type.code] || user[agency_type.code] == agency.idx"
							:key="'agency_' + sub_index"
							:value="agency.idx"
						>{{ agency.name }}</option>
						</template>
					</select>
				</template>
			</template>
			<template
				v-if="option.agency_sub && agency.branchList"
			>
				<label class="box pa-5 size-px-12 vertical-middle ml-5"> <input type="checkbox" v-model="search.onlySub" value="true" class="ml-5 vertical-middle" @change="$emit('click', 1)" /> 직가맹점만 보기</label>
			</template>
			<slot
				name="add-front"
			></slot>
		</div>

		<div class="flex-1 text-right">

			<template
				v-if="option.is_search_date_type && !search_sub_none"
			>
				<button
					v-for="type in codes.search_date_type"
					:key="'search_date_type_' + type.type"
					class="pa-4-10 mr-5 vertical-middle"
					:class="search.search_date_type == type.type ? 'bg-identify' : 'box'"
					@click="setSearchDate(type.type)"
				>{{ type.name }}</button>
			</template>

			<span class="position-relative" style="z-index: 999">
				<input
					v-if="option.sDate"
					v-model="search.sDate"
					placeholder="검색 시작일"
					readonly
					class="box pa-5 text-center width-100px"
					@click="toPicker('s')"
				/>
				<v-date-picker
					v-if="datePickerStart"
					v-model="search.sDate"
					no-title
					scrollable
					class="position-absolute"
					style="top: 30px; left: -95px;"
					@change="clear"
				></v-date-picker>
			</span>

			<span
				v-if="option.sDate && option.eDate"
			> ~ </span>

			<span class="position-relative mr-5" style="z-index: 999">
				<input
					v-if="option.eDate"
					v-model="search.eDate"
					placeholder="검색 종료일"
					readonly
					class="box pa-5 text-center width-100px"
					@click="toPicker('e')"
				/>

				<v-date-picker
					v-if="datePickerEnd"
					v-model="search.eDate"
					no-title
					scrollable
					class="position-absolute"
					style="top: 30px; left: -95px; z-index: 999"
					@change="clear"
				></v-date-picker>

			</span>

			<div v-if="datePickerEnd || datePickerStart"  class="position-fixed width-100 full-height " style="left: 0; top: 0; z-index: 998;" @click="clear"></div>
			<slot
				name="add"
			></slot>

			<template
				v-for="(select, index) in option.select"
			>
				<select
					:key="'select_' + index"
					v-model="search[select.column]"
					class="pa-5-10 box vertical-middle mr-10 "
					@change="$emit('click', 1)"
				>
					<option
						:value="''"
					>{{ select.name }}</option>
					<option
						v-for="(item, item_index) in select.items"
						:key="'select_' + index + '_item_' + item_index"
						:value="item.column"
					>{{ item.name }}</option>
				</select>
			</template>

			<select
				v-show="false"
				class="pa-5-10 mr-10"
				v-model="search.list_cnt"
				@change="$emit('click', 1)"
			>
				<option
					v-for="cnt in codes.list_cnt"
					:key="'cnt_' + cnt"
					:value="cnt"
				>{{ cnt }} 건씩 보기</option>
			</select>

			<select
				class="pa-5-10 mr-10"
				v-model="search.search_type"
			>
				<option
					:value="''"
				>검색조건</option>
				<option
					v-for="type in option.search_type"
					:key="'search_type_' + type.column"
					:value="type.column"
				>{{ type.name }}</option>
			</select>

			<input
				v-model="search.keyword"
				class="pa-5-10 box vertical-middle mr-10 "
				placeholder="검색어를 입력하세요"
				@keyup.enter="$emit('click', 1)"
			/>

			<button
				class="bg-identify pa-5-10 vertical-middle mr-10"
				@click="$emit('click', 1)"
			>검색</button>

			<button
				class="bg-identify-outline pa-5-10 vertical-middle mr-10"
				@click="$emit('reset')"
			>초기화</button>

			<button
				v-if="option.is_excel"
				class="btn-success pa-5-10 vertical-middle mr-10"
				@click="$emit('toExcel')"
			>EXCEL</button>

			<button
				v-if="option.is_item"
				class="btn-success pa-5-10 vertical-middle mr-10"
				@click="$emit('toItem')"

			>등록</button>

			<slot
				name="last"
			></slot>
		</div>
	</div>
</template>

<script>

export default {
	name: 'Search'
	,props: ['search', 'option', 'search_sub_none']
	,components: {}
	,data: function(){
		return {
			datePickerStart: false
			,datePickerEnd: false
			, upperIdx: ''
			, item_agency: {

			}
			,is_modal: false
			,modal_option: {
				title: '상위 영업단 조회'
				,top: true
				,bottom: false
			}
			,item: {
				type: 99
			}
			,agency_list: {
				branchList: []
				, distributorList: []
				, agencyList: []
				, resellerList: []
			}
			,agency: {
				branchList: this.user ? (this.user.branchIdx ? this.user.branchIdx : '') : ''
				, distributorList:  this.user ? (this.user.distributorIdx ? this.user.distributorIdx  : '') : ''
				, agencyList:  this.user ? (this.user.agencyIdx ? this.user.agencyIdx  : '') : ''
				, resellerList:  this.user ? (this.user.resellerIdx ? this.user.resellerIdx  : '') : ''
			}
		}
	}
	,computed: {

	}
	,methods:{
		setSearchDate: function(type){
			this.$emit('setSearchDateType', type)
		}
		, clear: function(){
			this.datePickerStart = false
			this.datePickerEnd = false
			this.is_modal = false
		}
		, toPicker: function(type){
			this.clear()
			if(type == 's'){
				this.datePickerStart = true
			}else{
				this.datePickerEnd = true
			}
			this.$emit('setSearchDateType', 'free')
		}
		,getAgencyList: async function(e, type){
			let upperIdx = ''
			if(this.user) {
				if (this.user.resellerIdx) {
					upperIdx = this.user.resellerIdx
				} else if (this.user.agencyIdx) {
					upperIdx = this.user.agencyIdx
				} else if (this.user.branchIdx) {
					upperIdx = this.user.branchIdx
				} else if (this.user.distributorIdx) {
					upperIdx = this.user.distributorIdx
				}
			}
			if(e){
				upperIdx = e.target.value
			}

			try{
				//this.$layout.onLoading()
				let result = await this.$Axios({
					method: 'get'
					, url: 'common/upper'
					, data: {
						upperIdx: upperIdx
					}
				})
				if(result.success){
					this.next_list = result.data

					if(type == 'branchList'){
						this.agency_list.distributorList = this.next_list.distributorList
						this.agency_list.agencyList = this.next_list.agencyList
						this.agency_list.resellerList = this.next_list.resellerList
						this.agency.distributorList = ''
						this.agency.agencyList = ''
						this.agency.resellerList = ''
						this.$emit('agency', this.agency)
					}else if(type == 'distributorList') {
						this.agency_list.agencyList = this.next_list.agencyList
						this.agency_list.resellerList = this.next_list.resellerList
						this.agency.agencyList = ''
						this.agency.resellerList = ''
						this.$emit('agency', this.agency)
					}else if(type == 'agencyList'){
						this.agency_list.resellerList = this.next_list.resellerList
						this.agency.resellerList = ''
						this.$emit('agency', this.agency)
					}else if(type == 'resellerList'){
						//
						this.$emit('agency', this.agency)
					}else{
						this.agency_list = this.next_list
					}
				}else{
					this.$layout.setNotify( { type: 'error', message: result.message})
				}
			}catch(e){
				console.log(e)
			}finally {
				//this.$layout.offLoading()
			}
		}
		,isAgency: function(){
			this.is_modal = true
		}
		,resetUpper: function(){
			this.upperIdx = ''
			this.item.upper_name = ''
		}
		,nextUpper: function(type, sub){
			this.upperIdx = sub.idx
			this.getAgencyList(type)
		}
		, setUpper: function(item, item_list){

			this.item.branchIdx = item_list.branchList.idx
			this.item.upper_name = item_list.branchList.name
			this.item.distributorIdx = ''
			this.item.agencyIdx = ''
			this.item.reselletIdx = ''
			this.item.subReselletIdx = ''

			if(item.type >= item_list.distributorList.type) {
				this.item.distributorIdx = item_list.distributorList.idx
				this.item.upper_name += ' > ' + item_list.distributorList.name
			}

			if(item.type >= item_list.agencyList.type) {
				this.item.agencyIdx = item_list.agencyList.idx
				this.item.upper_name += ' > ' + item_list.agencyList.name
			}

			if(item.type >= item_list.resellerList.type) {
				this.item.reselletIdx = item_list.resellerList.idx
				this.item.upper_name += ' > ' + item_list.resellerList.name
			}

			if(item.type >= item_list.subResellerList.type) {
				this.item.subReselletIdx = item_list.subResellerList.idx
				this.item.upper_name += ' > ' + item_list.subResellerList.name
			}

			this.upperIdx = item.idx
			this.clear()
		}
	}
	,created() {
        this.$layout = this.$root.$children[0].$children[0]
        this.user = this.$layout.user

            // this.getBankList()
		if(this.option.agency){

			this.getAgencyList()
		}
	}
}
</script>